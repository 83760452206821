import { Link } from 'gatsby';
import React from 'react';

// Components
import { PostCard, Wrapper } from 'components';

// Layouts
import { PageLayout, SEOContent } from 'layouts';

// Hooks
import { use404PageSectionsQuery } from 'hooks';

// Styles
import {
  ErrorCode,
  ErrorDescription,
  ErrorLink,
  ErrorTemplate,
  HomePosts,
  inner,
  outer,
  PostFeed,
} from 'styles';

// Types
import { Posts } from './';

const title = 'Page not found';

const NotFoundPage = () => {
  const { sections } = use404PageSectionsQuery();

  return (
    <SEOContent css={HomePosts} title={title}>
      <PageLayout>
        <Wrapper>
          <main css={[ErrorTemplate, outer]}>
            <div css={inner}>
              <section style={{ textAlign: 'center' }}>
                <ErrorCode>404</ErrorCode>
                <ErrorDescription>{title}</ErrorDescription>
                <Link css={ErrorLink} to="/">
                  Go to the front page →
                </Link>
              </section>
            </div>
          </main>
          <aside css={outer}>
            <div css={inner}>
              <div css={PostFeed}>
                {sections.map(({ data }: Posts) => (
                  <PostCard
                    key={data.post_id}
                    post={data}
                    postId={data.post_id}
                  />
                ))}
              </div>
            </div>
          </aside>
        </Wrapper>
      </PageLayout>
    </SEOContent>
  );
};

export default NotFoundPage;
